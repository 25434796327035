.m-more-less-content {
    span {
      display: inline;
    }
    .m-show-more, .m-show-less {
      color: #007bff;
      cursor: pointer;
      font-style: italic;
    }
    .m-show-more {
      &:before{
        content: " ... Read more";
      }
    }
    .m-more-text {
      overflow: hidden;
      display: none;
    }
    .m-show-less {
      display: none;
      
      &:before{
        content: " Read less";
      }
    }
    &.m-display-more {
      .m-show-more {
        display: none;
      }
      .m-more-text {
        display: inline;
      }
      .m-show-less {
        display: inline;
      }
    }
  
  }