.package-ratings {
    display: block!important;
    padding: 1.25rem 0;
    .card {
        border: solid 1px #d9d9d9!important;
        border-radius: .25rem!important;
        margin-bottom: 1rem;
        .card-body {
            .card-title {
                font-weight: 700;
                color: #454545;
                font-size: 13pt;
                text-transform: capitalize;
            }
            p {
                font-size: 10pt;
                color: #70777f;
                text-transform: uppercase;
                margin-bottom: 0;
                span {
                    font-size: 12pt;
                    color: #215e9a;
                    text-transform: lowercase;
                }
            }
            img {
                padding-bottom: 10px;
                padding-top: 10px;
            }
        }
    }
}