#accommodation {
    h2 {
        font-weight: 700;
        font-size: 13pt;
        color: #454545;
        padding-left: 0;
        text-transform: uppercase;
        span {
            color: #ea4b2c;
        }
    }
    ol {
       // padding-left: 1.25rem;
        margin-bottom: 8px;
        border-bottom: solid 1px #d9d9d9;
        padding-bottom: 1rem;
        li {
            font-size: 12pt;
            color: #19bce5;
            text-transform: uppercase;
            line-height: 20pt;
        }
    }
}