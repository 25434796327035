/* Package Days */

.package-days {
    .card {
        background: transparent;
        border: none;

        .card-body {
            h2 {
                font-weight: 700;
                font-size: 13pt;
                color: #ea4b2c;
                padding-left: 0.5rem;

                i {
                    color: #454545;
                    padding-right: 0.25rem;
                    color: #ea4b2c;
                }
            }

            article {
                padding: 10px 25px;

                header {
                    .title {
                        font-weight: 700;
                        font-size: 15pt !important;
                        color: #454545;
                        display: inline;
                        padding-right: 1rem;

                        &:hover {
                            text-decoration: none;

                            color: #454545;
                        }
                    }

                    .vote {
                        font-size: 11pt;
                        display: inline-block;
                        color: #9f9f9f;

                        i.active {
                            color: #f8d25d;
                        }
                    }

                    ul {
                        border-top: solid 1px #d9d9d9;
                        border-radius: 0;
                        border-bottom: solid 1px #d9d9d9;

                        li {
                            background: no-repeat;
                            border: none;
                            font-size: 9pt;
                            text-transform: uppercase;
                            color: #454545;
                            padding-left: .5rem;

                            i {
                                color: #ea4b2c;
                                padding-right: .5rem;
                            }
                        }
                    }
                }

                .col-sm-8 {
                    .card-body {
                        padding: 1.25rem 0;

                        ul {
                            padding-left: 1rem;

                            li {
                                font-size: 11pt;
                                line-height: 20pt;
                                color: #454545;
                            }
                        }
                    }
                }

                a {
                    font-size: 13pt;
                    color: #19bce5;
                }
            }
        }
    }
}

/* Package Days Accommodation */

#accommodation {
    .package-days {
        .card {
            .card-body {
                padding: 1.25rem 0;

                h2 {
                    color: #ea4b2c !important;
                    padding-left: 0;
                    text-transform: capitalize !important;
                }

                article {
                    padding: 0;
                }
            }
        }
    }
}