.carousel-thumbnails {
    text-align: left;
}

.carousel-thumbnails .carousel-control-next, .carousel-control-prev {
    height: 120px;
    top: 420px;
    opacity: 1;
    background-color: rgba(245, 245, 245, 0.25);
    width: 120px;
}

.carousel-thumbnails .carousel-control-next {
    margin-right: 15px;
}

.carousel-thumbnails .carousel-control-prev {
    margin-left: 15px;
}

.carousel-thumbnails .carousel-item img {
    height: 540px;
    width: auto;
    object-fit: cover;
}
@media (max-width: 991.98px) {
    .carousel-thumbnails .carousel-item img {
        height: 400px;
   }
}
@media (max-width: 767.98px) {
    .carousel-thumbnails .carousel-item img {
        height: 200px;
   }
}
.carousel-thumbnails .carousel-item .carousel-caption {
    bottom: 0;
    background: #23639d;
    width: 100%;
    right: 0;
    left: 0;
    text-align: left;
}
.carousel-thumbnails .carousel-item .carousel-caption span {
    font-size: 13pt;
    color: white;
    padding-left: 20px;
}
.carousel-thumbnails .carousel-indicators {
    position: relative;
    margin-top: .5rem;
}
.carousel-thumbnails .carousel-indicators img {
    width: 140px;
    height: 80px;
    overflow: hidden;
    display: block;
    object-fit: cover;
}
@media (max-width: 991.98px) {
    .carousel-thumbnails .carousel-indicators img {
        height: 50px;
   }
}
@media (max-width: 767.98px) {
    .carousel-thumbnails .carousel-indicators img {
        height: 30px;
   }
}
.carousel-thumbnails .carousel-indicators li {
    width: 140px;
    height: 80px;
    border: none;
}
@media (max-width: 991.98px) {
    .carousel-thumbnails .carousel-indicators li {
        height: 50px;
   }
}
@media (max-width: 767.98px) {
    .carousel-thumbnails .carousel-indicators li {
        height: 30px;
   }
}
.carousel-thumbnails .carousel-indicators li.active {
    border: solid 2px #ea4b2c;
}
.carousel-thumbnails .carousel-control-next, .carousel-thumbnails .carousel-control-prev {
    position: absolute;
    top: 210px !important;
    z-index: 1;
    color: #fff !important;
    opacity: 1 !important;
}
@media (max-width: 991.98px) {
    .carousel-thumbnails .carousel-control-next, .carousel-thumbnails .carousel-control-prev {
        top: 110px !important;
   }
}
@media (max-width: 767.98px) {
    .carousel-thumbnails .carousel-control-next, .carousel-thumbnails .carousel-control-prev {
        display: none;
   }
}
.tab-package #accommodation .carousel-item img {
    height: 400px;
}
@media (max-width: 767.98px) {
    .tab-package #accommodation .carousel-item img {
        height: 200px;
   }
}
.tab-package #accommodation .carousel-thumbnails .carousel-indicators {
    padding-left: 0;
}
.tab-package #accommodation .carousel-thumbnails .carousel-indicators img {
    height: 60px;
}
@media (max-width: 767.98px) {
    .tab-package #accommodation .carousel-thumbnails .carousel-indicators img {
        height: 30px;
   }
}
.tab-package #accommodation .carousel-thumbnails .carousel-indicators li {
    height: 60px;
}
@media (max-width: 767.98px) {
    .tab-package #accommodation .carousel-thumbnails .carousel-indicators li {
        height: 30px;
   }
}
.tab-package #accommodation .carousel-control-next, .tab-package #accommodation .carousel-control-prev {
    top: 120px !important;
}
