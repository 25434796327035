/* Title Head Package */

.title-head-package {
  height: 200px;
  background: linear-gradient(to bottom, #0382a0 14%, #a4c8d3, #abcad4);
  text-align: center;

  h1 {
    color: white;
    font-size: 35pt;
    padding-top: 2.2%;
  }

  span {
    font-weight: 700;
    font-size: 13pt;
    color: white;
    line-height: 7;
  }

  .rating {
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    margin-top: 20px;
  }
}

.list-style li {
  list-style: disc;
}

/* Tabs Package */

.tab-package {
  .tab-content {
    border: solid 1px #dadada;
    border-top: none;
    background-color: #f7f7f7;
    padding: 20px;
  }

  .nav-tabs {
    .nav-link {
      background-color: #dfdfe0;
      border-color: #dadada #dadada #f7f7f7;
      border-radius: 0;
      color: #454545;
      font-size: 11pt;
      text-transform: uppercase;
      padding: 12px 17px;
      margin-right: 5px;
    }

    .nav-link.active {
      background-color: #f7f7f7;
      font-weight: 700;
    }
  }
}

/* Sidebar Right Asides */

.package-right-sidebar {
  border-radius: 0;
  border: none;
  margin-top: 0px !important;

  .list-group-item-action {
    background: #eaf6fd;
    padding: 10px;
    border: none;

    .list-group-item {
      background: none;
      border: none;
      padding: 0;
    }

    &.border-bottom {
      border-bottom: 3px solid #fff !important;
    }

    .offer {
      margin-bottom: 1rem;

      .bg-transparent {
        color: #eaf6fd;
      }

      p {
        font-size: 14px !important;
        color: #454545;
        margin-bottom: 0;
      }

      span {
        font-weight: 700;
        font-size: 22pt;
        color: #454545;

        &.primary {
          color: #ea4b2c;
        }
      }

      img {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    ul {
      padding-left: 15px;
      margin-bottom: 1rem;

      li {
        list-style: disc;
        font-size: 12pt;
        color: #454545;
      }
    }

    #accordion {
      .card {
        border: none;
        border-radius: 0;

        .card-header {
          border-radius: 0;
          background: #23639d;
          color: white;
          border-bottom: solid 1px white;
          font-weight: 100;
          text-transform: uppercase;
          font-size: 10pt;
          cursor: pointer;

          span {
            font-weight: 700;

            i {
              padding-left: 0.2rem;
              font-size: 11pt;
            }
          }
        }

        .card-body {
          font-size: 10pt;
          padding: 1rem;
        }

        .card-header[data-toggle="collapse"] {
          padding: 0.75rem 1.5rem 0.75rem 0.75rem !important;
        }

        .card-header[data-toggle="collapse"]::after {
          color: white;
          padding-right: 20px;
          font-size: 8pt;
          line-height: 48px;
        }
      }

      .widget-error {
        background: #eee;
        background: rgba(233, 233, 233, 0.9);
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: -2px;
        right: 0;
        z-index: 10000;
        text-align: center;
      }

      .widget-error {
        background: #facfce;
        background: rgba(250, 207, 206, 0.95);
        margin: -1px;
        border: 1px solid #fa4541;

        .widget-error-message {
          font-size: 14px;
          font-size: 0.875rem;
          padding: 0;
          width: 100%;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          margin-top: -150px;
          display: block;
        }
      }

      .widget-loader-global {
        background: #eee;
        background: rgba(233, 233, 233, 0.9);
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10000;
        text-align: center;

        .widget-loader-message {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          font-weight: bold;
          text-align: center;
          color: #333745;
          margin-top: -36px;
          text-transform: uppercase;
          padding-top: 55px;
        }
      }
    }

    #accordion.has-error .widget-error {
      display: block;
    }

    #accordion.initial-loading .widget-loader-global {
      display: block;
    }

    .price-average {
      padding: 0;

      li {
        text-transform: uppercase;
        font-size: 11pt;
        color: #454545;

        &:first-child {
          padding-bottom: 1rem;
        }

        span {
          font-weight: 700;
          font-size: 15pt;

          strong {
            font-size: 11pt;
            span {
              font-size: 11pt;
            }
          }
        }
      }
    }
  }
}

/* Maps */

.gmap {
  height: 500px;
}

.gm-style {
  font: 400 11px Roboto, Arial, sans-serif;
  text-decoration: none;

  iframe {
    overflow-clip-margin: content-box !important;
    overflow: clip !important;
    border-width: 2px;
    border-style: inset;
    border-color: initial;
    border-image: initial;
  }
}

.accomodation-list li a {
  color: #19bce5;

  &:hover {
    text-decoration: none;
    color: #19bce5;
  }
}

.gm-style-moc {
  background-color: rgba(0, 0, 0, 0.45);
  pointer-events: none;
  text-align: center;
  -webkit-transition: opacity ease-in-out;
  transition: opacity ease-in-out;
}

.gm-control-active > img:nth-child(1) {
  display: block;
}

.gm-style img {
  max-width: none;
}

.gm-control-active > img {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: none;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

canvas {
  overflow-clip-margin: content-box;
  overflow: clip;
}

.gm-style .gm-style-cc a,
.gm-style .gm-style-cc button,
.gm-style .gm-style-cc span,
.gm-style .gm-style-mtc div {
  font-size: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.gm-style .gm-style-cc span {
  outline-offset: 3px;
}

.gm-style .gm-style-cc a,
.gm-style .gm-style-cc button,
.gm-style .gm-style-cc span,
.gm-style .gm-style-mtc div {
  font-size: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.gm-style .gm-style-cc a,
.gm-style .gm-style-cc button,
.gm-style .gm-style-cc span {
  outline-offset: 3px;
}

.gm-style .gmnoscreen,
.gmnoscreen {
  display: none;
}

.LGLeeN-keyboard-shortcuts-view {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;

  tr {
    display: table-row;
  }

  table {
    display: table;

    border-collapse: collapse;
  }

  table,
  .LGLeeN-keyboard-shortcuts-view tbody,
  .LGLeeN-keyboard-shortcuts-view td,
  .LGLeeN-keyboard-shortcuts-view tr {
    background: inherit;
    border: none;
    margin: 0;
    padding: 0;
  }
}

/*Flights*/
.flights.card .card-body ul.timeline > li p:first-child {
  color: #ea4b2c;
  padding-right: 1rem;
}

@media (max-width: 767.98px) {
  #attractions section.attr-exc .card .card-body {
    padding: 1.5rem 0 0;
  }

  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 1199.98px) {
  .title-head-package h1 {
    font-size: 200%;
    padding-top: 4%;
  }

  .tab-package ul#package-tabs {
    display: block;
    text-align: center;
  }

  .main-content .tab-content {
    margin-top: 2rem;
  }

  .tab-package .nav-tabs .nav-link {
    margin-right: 0;
  }
}

@media (max-width: 767.98px) {
  .title-head-package h1 {
    font-size: 150%;
    padding-top: 8%;
  }
}

/*Collapse effect*/
[data-toggle="collapse"][aria-expanded="false"] {
  .less {
    display: none;
  }

  .more {
    display: block;
  }
}

[data-toggle="collapse"][aria-expanded="true"] {
  .less {
    display: block;
  }

  .more {
    display: none;
  }
}

.btn-lateral {
  background-color: #ea4b2c;
  color: #fff;

  &:hover{
    background-color: #23639d;
    color: white;
  }
}

@media (min-width: 768px) {
  .package {
    div.col-md-9 {
      -ms-flex: 0 0 72% !important;
      flex: 0 0 72% !important;
      max-width: 72% !important;
    }

    div.col-md-3 {
      -ms-flex: 0 0 28% !important;
      flex: 0 0 28% !important;
      max-width: 28% !important;
    }
  }
}

@media (max-width: 1199.98px) {
  .package div.large-100 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    padding: 0 0.75rem !important;
  }
}

.promotion-home {
  .title-special {
    color: #454545;
    font-size: 18pt;
    line-height: 24pt;

    &:hover {
      color: #007bff;
      text-decoration: underline;
    }
  }
  .promotion-top {
    .price-tag {
      width: 97px;
      right: 40px;
      top: 0;
    }

    .author-image {
      border-radius: 50%;
    }

    .text-offert {
      position: absolute;
      inset: 0;
      top: 2px;
      z-index: 2;

      p {
        margin-bottom: 0px;
        color: white;
        font-size: 8pt;
        font-weight: 100;
      }

      p.price-h {
        font-weight: 700;
        font-size: 18pt;
        margin-top: -3px;
      }

      p.per-person {
        margin-top: -8px;
        font-weight: 400;
      }

      p.price-l {
        font-size: 12pt;
        margin-top: -10px;
      }

      p.p-from {
        margin-top: -8px;
      }
    }

    .pretitle {
      font-weight: 700;
      font-size: 13pt;
      color: #ea4b2c;

      .coin {
        color: white;
        background: #ea4b2c;
        padding: 8px 14px;
        border-radius: 50%;
        margin-right: 0.34rem;
      }
    }

    h2 {
      color: #454545;
      font-size: 18pt;
      line-height: 24pt;
    }

    .list-group-item {
      border: none;

      .place {
        font-weight: 100;
        font-size: 9pt;
        text-transform: uppercase;
        display: inline-block;
      }

      .type {
        font-size: 9pt;
        text-transform: uppercase;
        font-weight: 700;
      }

      .vote {
        font-size: 9pt;
        display: inline-block;
      }
    }

    .media .media-body p {
      text-wrap: pretty;

      span {
        color: #19bce5;
      }
    }
  }

  .enlace-container {
    margin-top: 2rem;
    .enlace-button {
      background: #ea4b2c;
      color: white;
      border-radius: 5px;
      font-size: 11pt;
      padding: 10px 20px;
      margin-top: 15px;

      &:hover {
        background-color: #1c5d94;
      }
    }
  }

  .fa-map-marker {
    color: #00a659;
  }

  .also-like {
    .container {
      background: #e9f6fe;
      padding: 15px;

      .card .card-body {
        color: #454545;

        .place {
          font-weight: 100;
          font-size: 9pt;
          text-transform: uppercase;
        }

        p.title {
          font-size: 11pt !important;
          display: -webkit-box;
          line-height: 160%;
          color: #454545;
          font-weight: 600;
        }

        p {
          font-size: 9pt;
          margin-bottom: 0;
          line-height: 15pt;
        }
      }

      .card {
        background: #e9f6fe;

        &:hover {
          color: #454545;

          p.title {
            color: #007bff;
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media (min-width: 1199.98px) and (max-width: 1431px) {
    .special-offers .column-reverse .col-md-4 {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }

  @media (min-width: 1199.98px) and (max-width: 1431px) {
    .special-offers .column-reverse .col-md-8 {
      flex: 0 0 60%;
      max-width: 60%;
    }
  }

  @media (min-width: 992px) {
    .special-offers .column-reverse .media-large-none .card-header button {
      display: none;
    }
  }

  .navbar-light button {
    background: transparent;
    padding: 0.75rem;
  }

  @media (min-width: 992px) {
    .special-offers .column-reverse .collapse {
      display: block;
    }
  }

  .card-deck {
    .card {
      margin-inline: 1% !important;
    }
  }

  .special-offers h2 {
    margin-top: 0;
    font-weight: 700;
    color: #454545;
    font-size: 20pt;

    span {
      color: #ea4b2c;
    }
  }

  .owl-carousel {
    margin-top: 25px;

    .owl-next {
      right: -2%;

      width: 23px;
      height: 35px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: block !important;
      background-color: #1c5d94 !important;
      border-radius: 5px;
      color: white !important;

      &.disabled {
        background-color: #808080 !important;
      }
    }

    .owl-item {
      display: flex;
      justify-content: center;
    }

    .owl-prev {
      left: -1%;
      width: 23px;
      height: 35px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: block !important;
      background-color: #1c5d94 !important;
      border-radius: 5px;
      color: white !important;

      &.disabled {
        background-color: #808080 !important;
      }
    }

    .owl-dots {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .owl-dot.active span {
        background: #1c5d94;
      }

      .owl-dot {
        zoom: 1;

        background: 0 0;
        color: inherit;
        border: none;
        padding: 0 !important;
        font: inherit;
      }
    }
  }

  .recent-collections .card-collection .card-deck {
    margin-right: auto;
    margin-left: auto;
  }

  .special-offers.others ul li {
    float: left;
    width: 25%;
    display: inline;
    padding: 1rem 15px 0 15px;

    a {
      font-size: 13pt;
      font-weight: 700;
      color: #19bce5;
    }
  }
}

@media (max-width: 991.98px) {
  .promotion-home .media-large-100 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (max-width: 991.98px) {
  .promotion-home {
    .promotion-top .col-md-6.media-large-100.pl-3 {
      padding: 1.5rem !important;
    }

    .column-reverse {
      flex-direction: column-reverse;
    }
  }
}

@media (max-width: 1199.98px) {
  .promotion-home .special-offers.others ul li {
    width: 50%;
  }
}

@media (max-width: 767.98px) {
  .promotion-home {
    .also-like .container .card {
      padding-left: 15px !important;
      padding-right: 15px !important;

      .card-img {
        width: 100%;
        height: 200px !important;
      }

      .card-body {
        padding-block: 1.3rem !important;

        h3 {
          font-size: 14pt;
        }

        p {
          font-size: 12pt;
        }
      }
    }

    .special-offers.others ul li {
      width: 100%;
    }
  }
}

.card.form-special-offer {
  border: solid 5px #f2f2f2;
  background: #e8e8e8;
  border-radius: 8px;

  p {
    color: #454545;
  }

  .card-header {
    background: transparent;
    border: none;

    p {
      font-weight: 800;
      font-size: 12pt;
      line-height: 16pt;

      span {
        color: #ea4b2c;
      }
    }
  }

  .card-body {
    padding: 0 1rem;

    .form-group label {
      margin-bottom: 0;
      font-size: 12pt;
      color: #23639d;
    }

    p {
      font-weight: 700;
      margin-bottom: 5px;
      font-size: 11pt;

      span {
        color: #19bce5;
      }
    }
  }

  .card-footer {
    border-top: none;
    padding-top: 0;
  }
  button.principal {
    background-color: #ea4b2c;
    color: #fff;
  }
}

.text-small.special-offers {
  font-size: 9pt;

  span {
    color: #19bce5;
  }
}

.recomendation {
  font-size: 1.2rem;
  font-style: italic;
  .fa {
    font-size: 2.5rem;
    color: #facfce;
  }
}

.calendar-picker {
  border: 1px solid #ddd;
  font-size-adjust: 0.47;
  font-weight: normal;
  font-style: normal;
  z-index: 5;
  display: block;
  position: relative;
  padding: 0px;
  color: #333;

  .calendar-header {
    background: #fff !important;
    position: relative;
    text-align: center;
    padding: 2px 0;
    color: #333 !important;

    .left {
      float: left;
      background-color: #fff !important;
      padding-inline: 5px;
    }
    .right {
      float: right;
      background-color: #fff !important;
      padding-inline: 5px;
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    thead {
      background-color: #f4f4f4;
      tr {
        background: #f4f4f4;

        th {
          text-transform: uppercase;
          background: #f4f4f4;
          border-right: 0 !important;
          font-style: italic;
          height: 24px;
        }
      }
    }
    tbody td:not(.today)::before,
    tbody td:not(.today)::after {
      content: " ";
      display: block;
      height: 3px;
      width: 8px;
      border-bottom: 4px solid transparent;
      position: absolute;
      bottom: 5px;
    }
    tbody td::before {
      left: 60%;
      margin-left: -10px;
    }

    tbody td::after {
      right: 55%;
      margin-right: -10px;
    }
    td {
      position: relative;

      &.disabled {
        background-color: #f7fbfc;
      }

      &.today {
        background-color: #23639d !important;
        border: 1px solid #23639d;
        color: #fff !important;
      }

      &:not(.disabled) {
        cursor: pointer;
        &:hover {
          border: 1px solid #19bce5;
        }
      }
    }

    td:not(.disabled).is-special::before,
    td:not(.disabled).is-special::after {
      border-color: #ea4b2c;
    }

    td:not(.disabled).is-special.is-best-value::after,
    tbody tr:nth-child(2) td.is-special:not(.disabled):nth-child(4)::after {
      border-color: #19bce5;
    }
  }
}

.package-right-sidebar .list-group-item-action #accordion {

  &.has-error .widget-error, &.has-error #widget{
    display: block;
  }

  &.load .widget-loader-global, &.load #widget{
    display: block;
  }

  #widget{
    display: none;
  }
  .widget-error {
    background: #eee;
    background: rgba(233, 233, 233, 0.9);
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: -2px;
    right: 0;
    z-index: 10;
    text-align: center;

    .btn{
      background-color: #fa4541;
      border-radius: 5px;
      padding: 5px 10px;
      color: white;
    }
  }

  .widget-error {
    background: #facfce;
    background: rgba(250, 207, 206, 0.95);
    margin: -1px;
    border: 1px solid #fa4541;
    z-index: 100;

    .widget-error-message {
      font-size: 14px;
      font-size: 0.875rem;
      padding: 0;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin-top: -150px;
      display: block; 
      z-index: 1000;
    }
  }

  .widget-loader-global {
    background: #eee;
    background: rgba(233, 233, 233, 0.9);
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10000;
    text-align: center;

    .widget-loader-message {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      font-weight: bold;
      text-align: center;
      color: #333745;
      margin-top: -36px;
      text-transform: uppercase;
      padding-top: 55px;
    }
  }
}
