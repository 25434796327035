#location {
    aside {
        li {
            background: none;
            border: none;
            padding-top: 0;
            a {
                font-weight: 700;
                font-size: 13pt;
                color: #19bce5;
                margin-bottom: 0;
                display: inline;
                padding-right: 1rem;
                display: block;
            }
            .vote {
                font-size: 11pt;
                display: inline-block;
                color: #9f9f9f;
                i.active {
                    color: #f8d25d;
                }
            }
            span {
                font-size: 12pt;
                color: #454545;
                display: block;
            }
        }
    }
    .link-location-table{
        li{
            margin-left: 0 !important;
        }
    }
    section {
        h2 {
            border-bottom: solid 1px #d9d9d9;
            border-top: solid 1px #d9d9d9;
            padding: 1.25rem 0;
            margin: 1.5rem 0;
            font-size: 18pt;
            font-weight: 700;
            color: #454545;
        }
        h3 {
            font-size: 15pt;
            font-weight: 700;
            color: #454545;
            margin-bottom: 1.25rem;
        }
        .card {
            height: 100%;
            background: none;
            border: none;
            border-radius: 0;
            border-right: solid 3px #eee;
            .card-body {
                padding: 0 1rem 0 0;
                h3 {
                    &.card-title {
                        font-size: 13pt;
                        text-transform: uppercase;
                        color: #454545;
                    }
                }
                ul {
                    padding-left: 0;
                    li {
                        display: block;
                    }
                }
            }
        }
    }
}