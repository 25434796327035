.filters {
    background-color: #206096;

    span {
        color: white;
        font-size: 14pt;
        text-transform: uppercase;
    }

    span.first-line {
        font-weight: 400;
    }

    span.second-line {
        font-weight: 700;
        font-size: 17pt;

        &:last-child {
            color: #ea4b2c;
        }
    }

    .label {
        font-weight: 400;
        color: white;
        font-size: 9pt;
    }

    button {
        font-weight: 400;
    }
}

.display-flex {
    display: flex;
}