.text-search-result {
    h1 {
        font-size: 35pt;
        color: #23639d;
        line-height: 1.2;
    }
    .text-result {
        background: #e4f3fa;
        border-top: 1px solid #b6b6b6 !important;
        p {
            font-weight: 100;
            font-size: 15pt;
            color: #454545;
            margin-bottom: 0;
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }
    .count-result {
        border-bottom: 1px solid #b6b6b6 !important;
        .container {
            @media (max-width: 767.98px) {
                display: block !important;
                .form-group {
                    display: contents;
                    label {
                        width: 35%;
                        justify-content: left;
                    }
                    select {
                        width: 65%;
                        justify-content: left;
                    }
                }
            }
        }
        p, label {
            font-weight: 700;
            font-size: 15pt;
            color: #454545;
            margin-bottom: 0;
            padding-top: 18px;
            padding-bottom: 18px;
        }
        label {
            text-transform: uppercase;
            padding-right: 1rem;
        }
    }
    .col-md-9.media-large-100 {
        @media (max-width: 1199.98px) {
            flex: 0 0 70%;
            max-width: 70%;
        } 
    }
    .col-md-3.media-large-100 {
        @media (max-width: 1199.98px) {
            flex: 0 0 30%;
            max-width: 30%;
        } 
    }
    article {
        margin-bottom: 2rem;
        h2 {
            font-size: 15pt;
            font-weight: 700;
            color: #215e9a;
            i {
                font-size: 7pt;
                color: #b6b6b6;
                vertical-align: middle;
                padding-right: .5rem;
            }
        }
        a {
            font-size: 13pt;
            color: #454545;
        }
        p {
            margin-top: .75rem;
            span {
                &.date {
                    color: #454545;
                }
                &:not(.date) {
                    background: #f5ef53;
                }
            }
            i {
                &.fa-circle {
                    font-size: 3pt;
                    vertical-align: middle;
                }
            }
        }
    }
}