.attractions {
    height: 70px;
    border-bottom: solid 1px #d9d9d9;

    span {
        color: #454545;
    }
}

.attr-exc {
    .card-body {
        padding: 0 1.5rem;
    }

    .card {
        background: no-repeat;
        border: none;
        border-bottom: solid 1px #d9d9d9;
        border-radius: 0;

        img {
            border-radius: 0;
            height: 188px;
            object-fit: cover;
        }

        .card-body {
            h2 {
                font-weight: 700;
                font-size: 18pt;
                color: #454545;
            }

            p.place {
                font-weight: 400;
                font-size: 10pt;
                text-transform: uppercase;
                display: inline-block;
                color: #767676;

                .fa-map-marker {
                    color: #00a659;
                }
            }
        }
    }
}

@media (max-width: 767.98px) {
    #attractions aside {
        height: 100px;
    }

    .pagination {
        display: block;
        text-align: center;
    }
}