.total-results {
    span.results {
        font-weight: 700;
        font-size: 13pt;
        color: #454545;
        @media (max-width: 1199.98px) {
            padding: 1rem 0 !important;
            display: block !important;
        }
    }
    label.sort {
        font-weight: 700;
        font-size: 13pt;
        color: #454545;
        text-transform: uppercase;
        @media (max-width: 767.98px) {
            float: none !important;
        }
    }
    .custom-checkbox {
        label {
            font-size: 13pt;
            color: #215e9a;
            text-transform: uppercase;
        }
    }
    .col-md-8 {
        @media (max-width: 1199.98px) {
            display: block !important;
        }
    }
    .col-md-4 {
        @media (max-width: 767.98px) {
            padding-top: 1.5rem;
            justify-content: flex-start !important;
        }
    }
    .input-group {
        width: 50%;
        margin-left: 12px
    }
    .input-group-append {
        position: absolute;
        right: 33px;
        top: 1px;
        z-index: 1000;
        span {
            background: white;
            border: none;
            i {
                line-height: 1.5;
            }
        }
    }
}