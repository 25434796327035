@import "../components/forms/phone-control/phone-control.less";
@import "../components/carousel/carousel.less";
@import "../components/pages/layouts-two-columns/faqs/faq.less";
@import "../components/text/more-less.less";
@import "../components/footer/footer.less";
@import "../components/pages/layouts-two-columns/about-us/about-us.less";
@import "../components/tabs/tabs-inclusive-holiday.less";
@import "../components/pages/layouts-two-columns/hotel-details/hotel-details-layout.less";
@import "../components/pages/layouts-two-columns/hotel-details/hotel-details-overview.less";
@import "../components/pages/layouts-two-columns/special-offer/card-special-days.less";
@import "../components/pages/layouts-two-columns/special-offer/layout-special.less";
@import "../components/pages/layouts-two-columns/special-offer/special-ratings.less";
@import "../components/pages/layouts-two-columns/special-offer/tabs/tab-accommodation.less";
@import "../components/pages/layouts-two-columns/special-offer/tabs/tab-attractions.less";
@import "../components/pages/layouts-two-columns/special-offer/tabs/tab-itinerary.less";
@import "../components/pages/layouts-two-columns/special-offer/tabs/tab-location.less";
@import "../components/pages/layouts-two-columns/special-offer/tabs/tab-overview.less";
@import "../components/pages/layouts-two-columns/special-offer/met.less";
@import "../components/pages/layouts-two-columns/group-search/card-result.less";
@import "../components/pages/layouts-two-columns/group-search/cd-search.less";
@import "../components/pages/layouts-two-columns/group-search/filter-sort-total-results.less";
@import "../components/pages/layouts-two-columns/group-search/filters-left-sidebar.less";
@import "../components/pages/layouts-two-columns/group-search/search-no-matching.less";
@import "../components/pages/layouts-two-columns/group-search/text-search-result.less";
@import "../components/Filters/filters.less";
@import "../components/Filters/search-form.less";

/******************************/
/* Media queries breakpoints */
/****************************/
@grid-columns: 12;

// Ultra extra small screen
@screen-uxs: 440px;
@screen-uxs-min: @screen-uxs;

// Extra small screen / phone
@screen-xs: 480px;
@screen-xs-min: @screen-xs;
@screen-phone: @screen-xs-min;

// Small screen / tablet
@screen-sm: 768px;
@screen-sm-min: @screen-sm;
@screen-tablet: @screen-sm-min;

// Medium screen / desktop
@screen-md: 992px;
@screen-md-min: @screen-md;
@screen-desktop: @screen-md-min;

// Large screen / wide desktop
@screen-lg: 1200px;
@screen-lg-min: @screen-lg;
@screen-lg-desktop: @screen-lg-min;

// So media queries don't overlap when required, provide a maximum
@screen-uxs-max: (@screen-xs-min - 1);
@screen-xs-max: (@screen-sm-min - 1);
@screen-sm-max: (@screen-md-min - 1);
@screen-md-max: (@screen-lg-min - 1);

.thanks-page {
  h1 {
    font-family: "Water Brush", cursive !important;
    text-transform: capitalize !important;
    font-size: 100px !important;
    font-weight: 400 !important;
  }

  .hero-text {
    padding-bottom: 250px;
  }
}

.contact_address ul li a {
  font-size: 16px !important;
  margin-bottom: 15px !important;
  color: #727272 !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  line-height: 28px !important;
}

header {
  .relative-top {
    opacity: 0.98;
    width: 100%;
    padding: 10px 0;
    border-radius: 0px;
    background: #232a34;
    box-shadow: 0px 60px 60px rgba(0, 0, 0, 0.1);
    position: relative;
  }
}

.slicknav_nav {
  padding-top: 2rem !important;
}

#main-menu ul li a {
  font-size: 17px !important;
}

@media (max-width: 767.98px) {
  .site-logo img {
    width: 80px !important;
  }

  .thanks-page {
    h1 {
      font-size: 70px !important;
    }

    .hero-text {
      padding-bottom: 200px;
      padding-top: 200px;
    }
  }
}

/* Color Primary */

.btn-primary {
  background-color: #1c5d94 !important;
  border-color: #1c5d94 !important;
}

.color-primary,
.text-primary {
  color: #1c5d94 !important;
}

.bg-primary {
  background-color: #1c5d94 !important;
}

.border-primary {
  border-color: #1c5d94 !important;
}

/* Color Info */

.btn-info {
  background-color: #cb2325 !important;
  border-color: #cb2325 !important;
}

.color-info,
.text-info {
  color: #cb2325 !important;
}

.bg-info {
  background-color: #cb2325 !important;
}

.border-info {
  border-color: #cb2325 !important;
}

.call_to_action {
  background: #1c5d94;
  border: 1px solid #1c5d94;
  color: #fff;
  padding: 10px 30px;
  font-weight: 500;
  display: inline-block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 30px;
}

.field-validation-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.aspect-ratio {
  aspect-ratio: 16/9;
  width: 1140px !important;
  height: auto !important;
  object-fit: cover;

  @media (max-width: 991.98px) {
    width: 720px !important;
  }

  @media (max-width: 767.98px) {
    width: 100% !important;
  }
}

.aspect-ratio-iframe {
  aspect-ratio: 16/9;
  height: auto !important;
  object-fit: cover;

  iframe {
    height: auto;
  }
}

.single_tour_details {
  p {
    margin-bottom: 1rem;
  }
}

.blog_post_area h3 a,
.read-more {
  color: #1d293e;

  &:hover {
    color: #1c5d94;
  }
}

.blog_post_area h3 a {
  font-size: x-large;
  font-weight: 500;
}

.blog_area,
.blog_post_area {
  background: #e6f2ff;
}

ul.blog_pagination li a:active,
ul.blog_pagination li a.active {
  color: #fff;
  background: #1c5d94;
  border: 1px solid #1c5d94;
}

ul.blog_pagination li a {
  border: 1px solid #eee;
  border-radius: 30px;
  color: #1d293e;
  display: block;
  font-size: 18px !important;
  font-weight: 500;
  background: #fff;
  padding: 0 !important;
}

ul.blog_pagination li a.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  background-color: #e7e7e7;
  border: 1px solid #e7e7e7;
  color: #1d293e;

  :hover {
    background-color: #e7e7e7;
    border: 1px solid #e7e7e7;
    color: #1d293e;
  }
}

ul.blog_pagination li a.default {
  cursor: default;
}

ul.blog_pagination li a.default:hover {
  border: 1px solid #eee !important;
  color: #1d293e !important;
  background: #fff !important;
}

ul.blog_pagination li,
.tab-pane ul.blog_pagination li {
  margin-left: 0;
}

.caption-img {
  text-transform: none;
  padding-right: 10px;
  margin-bottom: 15px;
  display: inline-block;
  font-weight: 600;
  margin-top: 20px;
}

.tour_details {
  background: #e6f2ff;
}

.post-slide-blog {
  a {
    font-size: 15px !important;
    font-family: "Poppins", sans-serif !important;

    &:hover {
      color: #007bff !important;
      text-decoration: underline !important;
    }
  }

  div.BlogPostContent p:first-of-type::first-letter {
    -webkit-initial-letter: 3;
    initial-letter: 3;
    background: #e6f2ff;
    border: 1px solid #1c5d94;
    color: #1c5d94;
    padding: 1.6rem;
    margin: 0 1rem 0 0;
    margin-top: 1.5rem;
  }

  h2 {
    a {
      text-decoration: none !important;
    }
  }

  span.caption-img {
    a {
      text-decoration: none !important;
    }
  }
}

.text-hotel-content {
  height: 120px;
  overflow: hidden;
  margin-bottom: 1.5rem;
}

.single_package a.title {
  text-transform: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size:18px;
  margin-bottom: 5px;
  font-weight: 600;
  color: #454545;

  &:hover{
    color: #007bff;
    text-decoration: underline;
  }
}

a {
  font-size: 17px;
}

p,
a,
span {
  text-transform: none !important;
}

.vote {
  .active {
    color: #f8d25d;
  }
}

.tab-pane {
  ol {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  ul {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;

    li {
      margin-left: 44px;
    }
  }

  a {
    font-size: 15px !important;
    font-family: "Poppins", sans-serif !important;

    &:hover {
      text-decoration: underline;
    }
  }
}

.nav-pills {
  border: 1px solid rgba(0, 0, 0, 0.125);

  .nav-link {
    background-color: #f7f7f7;
    font-weight: 400;
    text-transform: uppercase;
    color: #454545 !important;
    font-size: 11pt !important;
    padding: 0.75rem 1.25rem;
    cursor: pointer;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);

    &:last-child {
      border-bottom: none;
    }

    &.active {
      background-color: #1c5d94;
      color: white !important;

      &:after {
        content: "\f105";
        font-family: "FontAwesome";
        color: white;
        font-size: 10pt;
        line-height: 48px;
        font-weight: 900;
        position: absolute;
        right: 30px;
        margin-top: -12px;
      }
    }
  }
}

ul {
  li {
    list-style: none;
  }
}

.social-item,
.share-this {
  a:hover {
    color: #222;
  }
}

.social-item a i {
  font-size: 20px;
}

.promotion-ctapromo {
  max-width: 930px;

  img {
    height: 230px;
  }

  .card-title {
    font-size: 24px;
    font-size: 1.5rem;
    font-size-adjust: 0.47;
    text-transform: none;
    margin: 10% 0 10px 0;
    color: white;
    line-height: 1.5rem;
  }

  .card-text {
    font-size-adjust: 0.47;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.1;
    color: white;
    text-align: center;
  }

  button {
    padding: 0.6em 0.7em;
    background: #71c393;
    font-weight: 700;
    font-size: 10pt;
  }
}

img.sep {
  margin-top: -105px;
  margin-left: -25px;
  width: 50px;
  background-color: #fff;
}

hr.separator {
  margin-top: 5rem;
  margin-bottom: 5rem !important;
  border-top: 5px solid rgba(0, 0, 0, 0.1) !important;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.more-hotels li span {
  position: initial !important;
}

.card-experts {
  background-color: #dfdfe0;

  .title-tab {
    font-weight: 700 !important;
    font-size: 12pt !important;
    color: #3a3a3a;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  p span {
    color: #24649e;
    font-weight: 700;
    font-size: 12pt;
  }
}

.might-also-like {
  h2 {
    font-weight: 700;
    font-size: 18pt;
    color: #454545;
  }

  .card {
    border: none;
    border-radius: 0;

    img {
      border-radius: 0;
    }

    .card-body {
      padding: 0;
      padding: 1rem 0;

      .title {
        font-weight: 700;
        font-size: 13pt !important;
        color: #19bce5;

        &:hover {
          color: #007bff;
          text-decoration: underline;
        }
      }

      p {
        font-size: 12pt;
        margin-bottom: 0;
        line-height: 20pt;
      }

      .vote {
        font-size: 9pt;
        display: inline-block;
        color: #9f9f9f;

        i.active {
          color: #f8d25d;
        }
      }
    }
  }
}

.page-item {
  &.next {
    border-radius: 0.35rem;
    border: 1px solid #e9e9e9 !important;

    .page-link {
      background: transparent;
      color: #454545;
      text-transform: uppercase;
      font-size: 11pt;
      padding: 12px 30px;

      &:hover {
        color: #d73d20;
        text-decoration: underline;
        background: transparent;
        border: none;
      }
    }
  }

  &.disabled {
    .page-link {
      background: transparent;
      color: #454545;
      text-transform: uppercase;
      font-size: 11pt;
      padding: 12px 30px;
    }
  }
}

.pagination {
  li {
    a {
      color: #454545;
      font-weight: 400;
      font-size: 11pt;
      padding: 12px 30px;
      border: none;
      margin-left: 0px;
      background: none;

      &.active {
        font-weight: 700;
      }

      &:hover {
        background: transparent;
        border: none;
      }
    }
  }
}

.tab-content .content-text {
  p {
    margin-bottom: 0.75rem;
  }

  li:before {
    content: " - ";
    font-weight: bold;
  }
}

.price-tag {
  position: absolute;
  right: 0;
  z-index: 1;
}

img.sep-shadow {
  position: absolute;
  z-index: 1;
  left: 0;
  height: 100%;
}

.card.special-offer {
  flex: 0 0 300px;
  max-width: 300px;
  margin-bottom: 10px;

  .card-title {
    font-weight: 700;
    font-size: 15pt;
    margin-bottom: 0;
    color: #767676;
    display: -webkit-box;
    overflow: hidden;
    &:hover {
      color: #007bff !important;
      text-decoration: underline;
    }
  }

  .text-horz-tag {
    font-weight: 700;
    color: white;
    font-size: 12pt;
    position: absolute;
    top: 20px;
    left: 65px;
    text-transform: uppercase;
  }

  .horz-tag {
    width: 270px;
    top: 10px;
    right: 0;
  }

  .card-body {
    background: #f3f2f2;
  }

  i {
    color: #ef8a77;
    font-size: 1.5rem;
  }

  .card-footer {
    background-color: #f3f2f2;

    button {
      width: 100%;
      text-transform: none;
      background-color: #669dd0 !important;
      border: none !important;
      color: white !important;
      padding: 12px !important;
    }
  }
}

.btn.orange {
  background-color: #d73d20;
  color: white;
}

.card-messages {
  background: rgba(255, 255, 255, 0.3) !important;
  padding: 5px !important;
  border: none !important;
  border-radius: 10px !important;

  .card {
    background: rgba(255, 255, 255, 0.9) !important;
    border-radius: 5px !important;

    .card-body {
      padding: 2.3rem 1.25rem !important;

      .container-card {
        .row {
          &.error {
            hr {
              border: solid 0.5px #dcdcdc;
              width: 20%;
              margin-top: 1.5rem;
              margin-bottom: 1.5rem;
            }
          }
        }
      }

      h1 {
        font-weight: 700;
        font-size: 42pt;
        color: #ea4b2c;

        @media (max-width: 991.98px) {
          font-size: 35pt;
        }
      }

      h2 {
        font-size: 38pt;
        font-weight: 700;
        color: #5ab2db;
        margin-bottom: 1.2rem;
      }

      h3 {
        font-size: 15pt;
        color: #23639d;
        margin-bottom: 1rem;

        @media (max-width: 1199.98px) {
          display: grid;
        }
      }

      p {
        font-size: 12pt;
        margin-bottom: 0;
        color: #23639d;
      }
    }
  }
}

body {
  scroll-behavior: smooth;
}

.bg-blue {
  background-color: #e6f2ff;
}

.owl-next {
  right: -3%;

  width: 23px;
  height: 35px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block !important;
  background-color: #23639d !important;
  border-radius: 5px;
  color: white !important;

  &.disabled {
    background-color: #808080 !important;
  }
}

.owl-prev {
  left: -3%;
  width: 23px;
  height: 35px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block !important;
  background-color: #23639d !important;
  border-radius: 5px;
  color: white !important;

  &.disabled {
    background-color: #808080 !important;
  }
}

.owl-dot.active span {
  background: #23639d !important;
}

.breadcrumbs {
  span {
    font-weight: 400;
    font-size: 11pt;
  }
}
.breadcrumb {
  align-items: baseline;
  border-bottom: #e6f2ff;
}
.breadcrumb-item+.breadcrumb-item::before {
  content: ">";
}
.breadcrumb-item {
  align-items: baseline;

  &.active{
    color: #687178;
  }

  a {
    color: #1c5d94;
    &:hover {
      color: #222;
    }
  }
}
.calendar-button {
  top: 7px;
  right: 10px;
}

.main-title {
  font-size: 3rem;
  font-weight: 500;

  &.small{
    font-size: 2.5rem;
  }
}

p.place{
  text-transform: capitalize;
}

img.logo-sep{
  width: 50px;
  padding-block: 2px;
}

i.calendar-arrow{
  top: 12px;
  right: 10px;
}

.footer-calendar{
  font-size: 10px;
  .red-tag{
    color: #ea4b2c;
  }
  
  .blue-tag{
    color: #19bce5;
  }
}