.card-result {
    .card-container {
        border: 1px solid #e3e6f0 !important;
    }

    .card {
        border: none;
        border-radius: 0;
        background: #f7f7f7;

        button {
            font-size: 1rem !important;
        }

        .fa-map-marker {
            color: #00a659;
        }

        img {
            border-radius: 0;

            &.price-tag {
                display: none;

                @media (max-width: 991.98px) {
                    display: block;
                    position: absolute;
                    right: 40px;
                    width: 120px;
                }

                @media (max-width: 767.98px) {
                    display: none;
                }
            }

            &.card-img-top {
                min-width: 212px;
                min-height: 120px;
            }
        }

        .card-footer {
            background: transparent;
            padding: 0;
            margin-top: .5rem;
        }

        button.bg-light {
            background: transparent;
            color: #454545;
            border-color: #454545;

            &:hover {
                background: #d73d20 !important;
                border-color: #d73d20 !important;
                color: white !important;
            }
        }

        .card-body {
            padding-top: 0;
            padding-bottom: 0;

            h2 {
                font-weight: 700;
                font-size: 15pt;
                color: #215e9a;
            }

            p.place {
                font-weight: 400;
                font-size: 9pt;
                text-transform: uppercase;
                display: inline-block;
                color: #454545;

                .fa-map-marker-alt {
                    color: #00a659;
                }

            }

            .vote {
                font-size: 9pt;
                display: inline-block;
                color: #9f9f9f;

                i.active {
                    color: #f8d25d;
                }
            }

            .type {
                font-size: 10pt;
                padding: 6px;
                background: #e5e5e5;
                color: #343745;
                border-radius: 8px;
            }
        }

        .text-offert {
            p {
                margin-bottom: 0;
            }

            p.one-style {
                font-size: 9pt;
                color: #cf3d21;
                text-transform: uppercase;
            }

            p.two-style {
                font-weight: 700;
                font-size: 22pt;
                color: #cf3d21;
                line-height: 26pt;
            }

            p.three-style {
                color: #454545;
                text-transform: lowercase;
                font-size: 7pt;
            }

            p.four-style {
                font-weight: 700;
                font-size: 14pt;
                color: #cf3d21;
                margin-top: -44px;

                span {
                    font-weight: 400;
                }
            }
        }

        .text-extra {
            span {
                font-size: 10pt;
                color: #454545;
            }
        }

        &.sm-offert {
            @media (max-width: 1199.98px) {
                flex: 0 0 100%;
                max-width: 100%;
                margin-top: 1.5rem;

                .col-md-6 {
                    text-align: center;

                    @media (max-width: 767.98px) {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                }

                .text-offert p.four-style {
                    margin-top: -58px;
                }
            }

            @media (max-width: 991.98px) {
                flex: 0 0 100%;
                max-width: 100%;
                margin-top: 0;

                .card-body {
                    padding: 1.25rem !important;
                }
            }

            @media (max-width: 767.98px) {
                flex: 0 0 100%;
                max-width: 100%;
                margin-top: 1.5rem;

                .card-body {
                    padding: 0 !important;

                    .text-offert {
                        display: flex;
                    }
                }
            }
        }

        &.sm-body {
            @media (max-width: 991.98px) {
                flex: 0 0 100%;
                max-width: 100%;
                margin-top: 1.5rem;
            }

            @media (max-width: 767.98px) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        &.sm-top-img {
            padding-right: 0;

            .text-offert.text-center {
                display: none;
            }

            @media (max-width: 991.98px) {
                flex: 0 0 100%;
                max-width: 100%;

                .text-offert.text-center {
                    display: block;
                    position: absolute;
                    right: 52px;
                    top: 15px;

                    p {
                        color: white !important;

                        &.two-style,
                        &.four-style {
                            margin-top: 10px;
                        }

                        &.three-style {
                            margin-top: 4px;
                        }
                    }
                }
            }

            @media (max-width: 767.98px) {
                margin-top: .5rem;

                .text-offert.text-center {
                    display: none;
                }
            }
        }
    }
}