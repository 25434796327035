.faqs_area {
    a:hover, a:active {
        color: #1c5d94 !important;
    }
    .schema-faq {
        h2 {
            padding-top: 1rem;
            padding-bottom: 1rem;
            margin-bottom: 1.5rem;
            margin-top: 1rem;
        }
        h3 {
            margin-bottom: 1.5rem;
        }
        ul {
            padding-left: 2rem;
        }
    }

    .tab-contents {
        a, li {
            text-decoration: none !important;
            font-size: 14px;
        }
        .card {
            background: #f5f5f5;
            .list-catg {
                .list-group-item {
                    border: none;
                    background: none;
                    padding: .5rem .6rem;
                    font-weight: 500;
                    font-size: 13pt;
                    color: #323e48;
                }
                ul {
                    padding-left: 2.5rem;
                    a {
                        text-decoration: none !important;
                    }
                    li {
                        padding: .15rem 0;
                    }
                    li::marker {
                        content: "-";
                        font-weight: 400 !important;
                        padding-right: 10px;
                    }
                }
            }
        }
    }

    .popular-qts {
            ul {
                padding-left: .6rem;
                li {
                    padding: .5rem 0;
                    font-size: 14px;
                }
                li::marker {
                    content: "";
                    font-weight: 400 !important;
                }
            }

    }

    @media (max-width: 991.98px) {
        .col-md-4.asides-faq {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .col-md-8.schema-faq {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}