/* Met */

.met-wrapper {
    overflow-x: auto;
}

table.met {
    text-align: center;
    font-size-adjust: 0.47;
    font-weight: normal;
    font-style: normal;
    font-size: 0.75rem;
    margin: 1em 0;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    thead {
        th {
            color: #999;
            background-color: transparent;
            abbr[title] {
                border-bottom: 0 none;
            }
        }
    }
    tbody {
        tr {
            background-color: transparent;
            border-bottom: 4px solid #f7f7f7;
        }
        .label {
            width: 12%;
            text-align: right;
            white-space: nowrap;
        }
        th {
            font-family: "Bliss Basic Bold","Trebuchet MS","Lucida Grande","Lucida Sans Unicode","Lucida Sans",sans-serif;
            font-size-adjust: 0.47;
            font-weight: bold;
        }
        th, td {
            border-left: 0 none;
            padding: 0.75em;
        }
        .i-1 {
            background-color: #DDF2D7;
        }
        .i-2 {
            background-color: #C5E9B3;
        }
        .i-3 {
            background-color: #D8E073;
        }
        .i-4 {
            background-color: #F2C976;
        }
        .i-5 {
            background-color: #F3AD8A;
        }
        .i-6 {
            background-color: #F4939D;
        }
        .i-7 {
            background-color: #F37497;
        }
        .i-8 {
            background-color: #F04F77;
        }
    }
}