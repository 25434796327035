.footer-menu ul {
    list-style-type: none;
}

.footer-menu ul li {
    display: inline-block;
    padding: 0px 12px;
    position: relative;
}

.footer-menu ul li a {
    color: #1c5d94;
    font-size: 16px;
    font-weight: 500;
    transition: .3s;
    font-family: 'Jost', sans-serif;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.footer p a {
    font-size: 15px !important;
    font-family: 'Poppins', sans-serif !important;
    &:hover {
        color: #007bff !important;
        text-decoration: underline !important;
    }
}