.search {
    footer {
        margin-top: 0 !important;
    }
    section {
        position: relative;
        overflow: hidden;
            h1 {
                color: white;
                font-size: 24pt;
            }
            .container {
                margin-top: 15%;
                margin-bottom: 15%;
                @media (min-width: 1200px) {
                    margin-top: 10%;
                    margin-bottom: 10%;
                }
            }
    }
    .card-form-search {
        background: rgba(255, 255, 255, 0.3);
        padding: 5px;
        border: none;
        border-radius: 10px;
        .col-md-9 {
            @media (max-width: 1199.98px) {
                display: block !important;
                text-align: center;
                p {
                    margin-bottom: 1rem !important;
                }
                input {
                    width: 78% !important;
                    margin-right: auto;
                    margin-left: auto
                }
            }
        }
        .card {
            background: rgba(255, 255, 255, 0.9);
            border-radius: 5px;
            .card-body {
                padding: 2.3rem 1.5rem;
                @media (max-width: 1199.98px) {
                    i {
                        padding-right: .5rem !important;
                        &.fa-moon {
                            padding-left: .75rem;
                        }
                    }
                }
                form {
                    .form-group {
                        margin-bottom: 1.7rem;
                        .col-sm-4 {
                            @media (max-width: 1335px) {
                                flex: 0 0 40%;
                                max-width: 40%;
                            }
                        }
                        .col-sm-8 {
                            @media (max-width: 1335px) {
                                flex: 0 0 60%;
                                max-width: 60%;
                            }
                        }
                    }
                    p {
                        margin-bottom: 0;
                        font-weight: 700;
                        font-size: 20pt;
                        color: #23639d;
                    }
                    i {
                        font-size: 16pt;
                        color: #eb7660;
                    }
                    label {
                        font-size: 15pt;
                        color: #23639d;
                        padding: 0;
                        line-height: 27pt;
                    }
                    input[type="number"] {
                        width: 65px;
                        display: inline;
                        margin-right: 1rem;
                        @media (max-width: 991.98px) {
                            width: 60%;
                        }
                    }
                }
            }
        }
    }
    .search-back {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}

