.inclusive-holiday {
  .tab-content {
    border: solid 1px #dadada;
    border-top: none;
    background-color: #f7f7f7;
    padding: 20px;
    margin-top: unset !important;
  }

  .cabecera {
    width: unset;
    position: absolute;
    top: -55px;
    display: inline-block !important;

    .pt-2 {
      margin-top: 10px;
    }
  }

  .deck-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    place-items: center;
  }

  .nav-tabs {
    .nav-link {
      background-color: #dfdfe0;
      border-color: #dadada #dadada #f7f7f7;
      border-radius: 0;
      color: #454545;
      font-weight: 700;
      font-size: 11pt;
      text-transform: uppercase;
      padding: 15px 30px;
      margin-right: 12px;
    }

    .nav-link.active {
      background-color: #f7f7f7;
    }
  }

  h2 {
    font-size: 15pt;
    color: #454545;
    padding-right: 1rem !important;
    margin-bottom: 0px;
    line-height: 1.5;
    margin-top: 20px;
  }

  .link {
    font-weight: 400;
    font-size: 11pt;
    color: #19bce5 !important;
    text-transform: uppercase;
  }

  .ellipse {
    aspect-ratio: 1;
    min-height: 80px;
    width: auto;
    padding: 10px;
    border-radius: 50%;
    background: #ea4b2c;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0px;
    margin-top: -75px;

    p {
      color: white;
      font-size: 13pt;
      text-transform: uppercase;
      line-height: 18pt;
      margin-top: -10px;

      span {
        display: block;
        font-weight: 700;
        font-size: 16pt;
      }
    }
  }

  &.tab-holiday {
    h2 {
      font-size: 15pt !important;
      width: unset !important;
    }
  }

  @media only screen and (max-width: @screen-xs-max) {
    .nav-tabs .nav-link {
      padding: 0.5rem 0.3rem;
      margin-right: unset;
    }
  }

  @media only screen and (max-width: @screen-uxs-min) {
    .header > div {
      width: 100%;
    }
  }

  @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
    .nav-tabs .nav-link {
      padding: 15px 20px;
    }
  }

  @media only screen and (max-width: 1100px) {
    .header {
      width: unset;
      position: relative;
    }
  }
}

.card.inclusive-card {
  flex: 0 0 300px;
  margin-bottom: 10px;
  max-width: 300px;

  .fa-map-marker {
    color: #00a659;
  }

  .italic{
    font-style: italic;
  }

  .card-body {
    .card-title {
      color: #454545;

      .fa-map-marker {
        color: #00a659;
      }
    }

    .card-title.type {
      font-size: 7pt;
      text-transform: uppercase;
    }

    .card-title.title {
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 0;
      display: block;
      &:hover {
        text-decoration: underline;
        color: #007bff;
      }
    }

    .card-title.place {
      font-weight: 100;
      font-size: 9pt;
      text-transform: uppercase;
      display: inline-block;
      width: max-content;

      
    }

    .vote {
      font-size: 9pt;
      display: inline-block;
      color: #9f9f9f;

      i.active {
        color: #f8d25d;
      }
    }

    .card-text {
      font-size: 11pt;
      color: #454545;
    }

    li {
      padding-left: 10px !important;
      margin-left: 20px !important;
      list-style: disc;
      font-size: 11pt;
      color: #454545;
    }

    li::marker {
      font-size: 10pt;
    }
  }

  .card-footer {
    button {
      background-color: #1c5d94 !important;
      color: white;
      padding: 12px 30px;
      text-transform: uppercase;

      &:hover {
        background-color: #ea4b2c !important;
      }
    }
  }

  .text-offert {
    position: absolute;
    inset: 0;
    z-index: 3;
    bottom: 80%;

    .offer-container {
      width: 100px;

      p {
        margin-bottom: 0px;
        color: white;
        font-size: 8pt;
        font-weight: 100;
      }

      p.price-h {
        font-weight: 700;
        font-size: 18pt;
        margin-top: -3px;
      }

      .p-from {
        margin-top: -10px;
      }

      p.price-l {
        font-size: 11pt;
        margin-top: -10px;

        strong {
          font-weight: 700;
        }
      }

      p.per-person {
        margin-top: -3px;
        font-weight: 400;
      }
    }

    .price-tag {
      width: 100px;
      right: 8px;
    }
  }
}

.card.inclusive-card.big {
  flex: none !important;
  margin-bottom: 10px;
  max-width: 340px !important;
  min-width: 280px;

  .card-footer a {
    color: #ea4b2c;
    font-size: 11pt;
  }

  .card-text {
    height: 75px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-title.title {
    min-height: 43px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.price-background {
  background-color: #333745;
  padding: 10px 20px;
  height: 65px;

  p{
    line-height: 120%;

    &.p-from, &.per-person{
        color:#9f9f9f;
    }
  }
}

.price-tag.price-background{
    width: 110px !important;
}
