#search-form-widget {
  background-color: #23639d;

  .col-title {
    flex: 0 0 20%;
    max-width: 20%;
    padding-left: 0.75rem;

    @media only screen and (max-width: @screen-uxs-max) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .col-body {
    flex: 0 0 80%;
    max-width: 80%;

    @media only screen and (max-width: @screen-uxs-max) {
      flex: 0 0 100%;
      max-width: 100%;
    }

  }

  .title {
    display: inline;

    span {
      color: white;
      font-size: 14pt;
      text-transform: uppercase;
    }

    span.first-line {
      font-weight: 400;
    }

    @media only screen and (max-width: @screen-uxs-max) {
      span.perfect {
        display: none;
      }

      span.first-line {
        font-size: 12px;
      }

      span.second-line {
        font-size: 20pt;
      }
    }

    span.second-line {
      font-weight: 700;
      font-size: 17pt;
    }
  }

  .separator {
    display: inline;
    position: relative;
    // padding-right: 30px;

    img {
      position: absolute;
      top: -60px;
      left: 60px;
    }

    @media only screen and (max-width: @screen-md) {
      display: none;
    }

  }

  .search-form {
    form {

      >div.display-flex {
        flex-wrap: wrap;
        align-items: flex-end;


        @media only screen and (max-width: @screen-uxs-max) {
          display: block;
          width: 100%;
        }

        .form-group {
          margin: 0 1% 15px;
          padding-right: 14px;
          position: relative;
          float: none;
          vertical-align: bottom;
          margin-bottom: 20px;

          @media only screen and (max-width: @screen-uxs-max) {
            margin: unset;

            &.nights {
              width: 35%;
              display: inline-block;

              .form-control {
                text-align: end;
              }
            }

            &.passengers {
              width: 60%;
              float: right;
            }
          }

          &>label:not(.search) {
            display: block;
            color: #fff;
            font-size: 12px;

            @media only screen and (max-width: @screen-uxs-max) {
              position: relative;
              top: 35px;
            }
          }

          >span.fa {
            pointer-events: none;
            position: absolute;
            top: 36px;
            left: auto;
            right: 18px;
            color: #fff;

            @media only screen and (max-width: 575px) {
              top: 41px;
            }
          }

          .form-control.search-only{
            width: 100%;
            border: unset;
            border-radius: unset;
            background-color: #fff;
            height: 33px;
            cursor: pointer;
            color: #454545;
          }

          >.form-control,
          .twitter-typeahead .form-control,
          >.ui-spinner {
            width: 100%;
            background-color: #43719d;
            border: unset;
            border-radius: unset;
            border-bottom: 1px solid #fff;
            height: 33px;
            cursor: pointer;

            &:focus+span.fa {
              color: #ea2c2c;
            }

            color: #fff;
            font-size: 10px;

            &::-webkit-input-placeholder {
              color: #fff;
              font-size: 10px;
            }

            &::-moz-placeholder {
              color: #fff;
              font-size: 10px;
            }

            &::-ms-placeholder {
              color: #fff;
              font-size: 10px;
            }

            &::placeholder {
              color: #fff;
              font-size: 10px;
            }

            @media only screen and (max-width: @screen-uxs-max) {
              text-align: right;
              background-color: unset;
              padding-right: 1.5rem;

              &::-webkit-input-placeholder {
                color: transparent;
              }

              &::-moz-placeholder {
                color: transparent;
                opacity: 0;
              }

              &:-moz-placeholder {
                color: transparent;
                opacity: 0;
              }

              &::-ms-placeholder {
                color: transparent;
              }

              &::placeholder {
                color: transparent;
              }
            }
          }

          &.going-to,
          &.flying-from {
            flex-basis: 160px;

            .form-control {
              cursor: auto;
            }
          }

          &.leaving-on,
          &.passengers,
          &.submit-btn {
            flex-basis: 120px;
          }

          &.nights {
            flex-basis: 0;

            .form-control {
              padding-right: 0;
              padding-left: 0.3rem;
              width: 20px;

              &:focus {
                background-color: inherit;
                color: #fff;
              }
            }
          }

          &.passengers {

            /* People details */
            .people-details {
              display: none;
              position: absolute;

              background-color: #fff;
              border: 1px solid #f2f2f2;
              border-top: 3px solid #ea2c2c;
              border-radius: 4px;
              box-shadow: 1px 1px 3px fade(black, 25%);
              z-index: 99;
              top: 60px;

              &:before {
                display: block;
                width: 0;
                height: 0;
                position: absolute;
                left: 65px;
                top: -15px;
                content: "";
                border-style: solid;
                border-width: 6px;
                border-color: transparent transparent #ea2c2c;
              }

              &.active {
                display: flex;
                flex-wrap: wrap;

                @media only screen and (max-width: @screen-uxs-max) {
                  display: block;
                  width: 180px !important;
                  padding: 10px 5px 5px;

                  .room {
                    margin-left: 13%;
                    text-align: center;
                  }

                  .group-trip-info {
                    >p {
                      b {
                        display: block;
                      }
                    }

                    .experts {
                      display: none;
                    }

                    .call {
                      margin-bottom: 0;

                      .text {
                        display: none;
                      }
                    }

                    .contact-us {
                      .click-here {
                        display: block;
                      }

                      .button {
                        padding: 12px 29px;
                      }
                    }
                  }
                }
              }
            }
          }

          &.submit-btn {
            padding-right: unset;
            flex-basis: 130px;

            .btn.btn-submit {
              align-self: flex-end;
              padding: 5px 35px;

              @media only screen and (max-width: @screen-uxs-max) {
                margin-top: 15px;
              }
            }
          }

          &.has-error {
            .form-control {
              background-color: #f7e1e1 !important;
            }
          }
        }
      }
    }
  }

  @media (min-width: @screen-xs) and (max-width: @screen-md-max) {
    .col-title {
      flex: 0 0 100%;
      max-width: 100%;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      margin-bottom: 2rem;
      border-bottom: 1px solid white;

      .title {
        br {
          display: none;
        }

        .first-line {
          margin-right: 5px;
        }
      }

      .separator {
        display: none;
      }
    }

    .col-body {
      flex: 0 0 100%;
      max-width: 100%;
    }

  }
}