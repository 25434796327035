.nav-pills {
    background: #f3f3f3;
    border: solid 1px #d7d7d7;
    .nav-link {
        border-bottom: solid 1px #d7d7d7;
        border-radius: 0 !important;
        padding: 1rem;
        color: #232a34 !important;
        font-weight: 500;
        font-size: 18px;
        &:last-child {
            border-bottom: none;
        }
        &:hover {
            background: #f3f3f3;
            color: #1c5d94 !important;
        }
        &.active {
            background: #f3f3f3;
            color: #1c5d94 !important;
        }
    }
}