.hotel_details {
  .more-hotels {

    h3 {
      font-weight: 700;
      font-size: 14pt;
      color: #454545;
      margin-bottom: 14px;
    }

    li {
      a {
        color: #19bce5 !important;
        font-size: 11pt !important;
        margin: 5px 0;
        font-weight: 700;

        &:hover {
          color: #19bce5 !important;
          text-decoration: none;
        }
      }

      .vote {
        padding-top: 0 !important;
      }

      p {
        padding-top: 5px;
      }

      .text-muted {
        margin-bottom: 0 !important;
        font-size: 11pt !important;
      }

      span {
        top: 0;
        right: 25px;
        color: #989898;
        font-size: 10pt;

        strong {
          color: #ea4b2c;
          font-weight: 700;
          font-size: 12pt;
        }

        &:last-child {
          top: 18px !important;
          right: 0 !important;
        }
      }
    }
  }
}

#toggle-collapse.readmore-home-details {
  color: #19bce5;

  &[data-toggle=collapse][aria-expanded=true] {
    .more {
      display: none;
    }

    .less {
      display: inherit;
    }
  }

  &[data-toggle=collapse][aria-expanded=false] {
    .less {
      display: none;
    }

    .more {
      display: inherit;
    }
  }
}


.hotel_details {
  .tab-content {
    h2 {
      font-size: 35pt;
      color: #23639d;
      line-height: 1.2;
    }

    .col-md-8 {
      .card {
        border: none;

        .card-body {
          padding: 0;
        }
      }

      a {
        font-size: 13pt !important;
        color: #19bce5 !important;
      }
    }

    .list-group {
      .list-group-item {
        font-weight: 700 !important;
        font-size: 12pt !important;
        color: #454545;
        text-transform: uppercase;
        margin-bottom: 8px;
        border: none;
        padding: 0;
        margin-bottom: 0;

        p {
          text-transform: capitalize !important;
          padding-top: 5px;
        }

        .vote {
          font-size: 9pt;
          display: block;
          color: #9f9f9f;
          padding-top: 5px;

          i.active {
            color: #f8d25d;
          }
        }
      }
    }

    .tab-pane ul li {
      @media (min-width: 768px) {
        margin-left: 35px !important;
      }

    }

    .desc-hotel-details {
      padding-left: 5px;

      .hotel-name {
        max-width: 60%;
      }

      .list-group {
        .list-group-item {
          p {
            font-weight: 100;
          }
        }
      }

      .text-offert {
        position: absolute;
        z-index: 3;
        inset: 0;
        margin-top: 16px;
        line-height: 42px;

        p {
          margin-bottom: 0px;
          color: white !important;
          font-weight: 100 !important;

          &.price-h {
            font-weight: 700 !important;
            font-size: 18pt;
            margin-top: -10px;
          }

          &.per-person {
            margin-top: -15px;
          }
        }
      }

      .tags {
        @media (min-width: 768px){
          padding-left: 43px !important;
        }
        

        li {
          display: inline-block;
          font-size: 10pt;
          padding: 6px 10px;
          background: #e5e5e5;
          color: #343745;
          border-radius: 8px;
          text-transform: uppercase;
          margin-left: 0 !important;
          margin-top: 5px;
        }
      }
    }

    .promotion-ctapromo {
      left: 50%;
      transform: translate(-50%, -50%);
      top: 50%;

      img {
        object-fit: cover;
      }
    }

    .more-hotels {
      h3 {
        font-weight: 700;
        font-size: 14pt;
        color: #454545;
        margin-bottom: 14px;
      }

      ul li {
        margin-left: 0 !important;
      }

      li {
        color: #19bce5 !important;
        text-transform: capitalize !important;
        font-size: 11pt !important;
        margin: 5px 0 !important;

        span {
          position: absolute;
          top: 0;
          right: 25px;
          color: #989898;
          font-size: 10pt;
          text-transform: lowercase;

          &:last-child {
            top: 18px !important;
            right: 0 !important;
          }

          strong {
            color: #ea4b2c;
            font-weight: 700;
            font-size: 12pt;
          }
        }

        .text-muted {
          margin-bottom: 0 !important;
        }
      }
    }

    .inclusive-holiday {
      margin-top: 83px;

      .col-md-7 {
        width: 50% !important;

        h2 {
          font-size: 15pt;
          color: #454545;
          line-height: 1.5;
        }
      }
    }

  }
}

@media (max-width: 1335px) and (min-width: 768px) {
  .hotel_details {
    .more-hotels li {
      .vote {
        margin-bottom: 0;
      }

      span {
        position: initial !important;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .hotel_details {
    .more-hotels .col-md-6:first-child {
      margin-bottom: 1.5rem;
    }
  }
}