.hotel_details {

  .lateral-button {
    button {
      background-color: transparent;
    }
  }

  
}

@media (max-width: 1199.98px) {
  .hotel_details .tab-content .col-md-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}