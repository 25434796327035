.search-no-matching {
    background: #e4f3fa;
    .container {
        margin-top: 15%;
        margin-bottom: 15%;
        @media (min-width: 1200px) {
            margin-top: 10%;
            margin-bottom: 10%;
        }
    }
    .col-md-6.mx-auto {
        @media (min-width: 768px) and (max-width: 991.98px) {
            flex: 0 0 65%;
            max-width: 65%;
        }
    }
    .card-messages {
        background: rgba(255, 255, 255, 0.3);
        padding: 5px;
        border: none;
        border-radius: 10px;
        .card {
            background: rgba(255, 255, 255, 0.9);
            border-radius: 5px;
            .card-body {
                padding: 3rem 5rem;
                @media (max-width: 767.98px) {
                    padding: 3rem 2rem;
                }
                h1 {
                    font-weight: 700;
                    font-size: 42pt;
                    color: #ea4b2c;
                }
                p {
                    font-size: 22pt;
                    margin-bottom: 0;
                    color: #23639d;
                    line-height: 26pt;
                }
                img {
                    transform: rotate(-180deg); 
                }
            }
        }
    }
    .looking-for {
        background-color: white;
        padding-bottom: 2rem;
        h2 {
            font-size: 20pt;
            color: #ea4b2c;
            text-transform: uppercase;
            position: absolute;
            background-color: #eaf6fd;
            padding: 0 35px;
            margin-top: 16px;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: white!important;
            text-align: center;
            @media (max-width: 767.98px) {
                width: 75%;
                font-size: 15pt;
            }
        }
        hr {
            border: solid 0.8px #24639e;
        }
        p.section-subtitle {
            margin-top: 2rem;
            font-weight: 400;
            font-size: 13pt;
            color: #68717b;
        }
        .container {
            padding-top: 5rem;
        }
        .inclusive-holiday .section-title {
            font-size: 15pt;
            color: #454545;
            padding-right: 1rem !important;
            margin-bottom: 0px;
            line-height: 1.5;
            margin-top: 20px;
        }
    }
}