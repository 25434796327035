/* Package Descripcion */

.package-descripcion {
    .card-header {
        background: #23639d;
        padding: 0;
    }

    .card-header:first-child {
        border-radius: calc(0.35rem - 1px) calc(0.35rem - 1px) 0 0;
    }

    .card-body {
        background: #eaf6fd;
        padding: 2rem;

        .card-text {
            font-size: 15pt;
            color: #454545;
        }

        a {
            font-size: 13pt;
            color: #19bce5;
        }
    }

    .card-footer {
        background: white;
        padding: 2rem;

        @media (max-width: 767.98px) {
            .card {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }



        img {
            margin-bottom: 10px;
            max-width: 300px;
            min-width: 200px;
        }

        span {
            font-weight: 700;
            font-size: 22pt;
            color: #454545;

            &.primary {
                color: #ea4b2c;
            }
        }

        .text-tag {
            position: absolute;
            top: 65px;
            left: 50%;
            transform: translate(-50%, -50%);

            p {
                font-weight: 700;
                color: white;
                text-transform: uppercase;
                font-size: 15pt;
                width: 100px;
            }
        }

        .card {
            background: transparent;
            border: none;
            padding: 0;

            .card-body {
                background: transparent;
                padding: 0;
                display: contents;

                ul {
                    li {
                        font-size: 12pt;
                        color: #454545;
                        padding-bottom: 8px;
                    }
                }
            }
        }
    }
}

/* Flights */

.flights {
    &.card {
        background: transparent;
        border: none;

        .card-body {
            h2 {
                font-weight: 700;
                font-size: 13pt;
                color: #ea4b2c;
                padding-left: 1.25rem;

                i {
                    color: #454545;
                    padding-right: 1rem;
                }
            }

            ul.timeline {
                list-style-type: none;
                position: relative;
            }

            ul.timeline:before {
                content: ' ';
                background: #bebebe;
                display: inline-block;
                position: absolute;
                left: 29px;
                width: 1px;
                height: 100%;
                z-index: 400;
            }

            ul.timeline>li {
                margin: 0;
                padding-left: 45px;
                display: flex;
                padding-top: 20px;
                padding-bottom: 20px;

                p {
                    font-size: 9pt;
                    text-transform: uppercase;
                    margin-bottom: 0;
                    line-height: 7px;
                    color: #454545;

                    &:first-child {
                        color: #ea4b2c;
                        padding-right: 1rem;
                    }
                }
            }

            ul.timeline>li:before {
                content: ' ';
                background: #bebebe;
                display: inline-block;
                position: absolute;
                border-radius: 50%;
                left: 26px;
                width: 7px;
                height: 7px;
                z-index: 400;
            }
        }
    }

}

@media (max-width: 767.98px) {
    .flights.card .card-body ul.timeline>li p {
        line-height: 1rem;
    }
}

.package-days {
    ul li {
        list-style: disc;
        margin-left: 0 !important;
    }
}