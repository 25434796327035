.timeline-itinerary {
    position: relative;
    padding: 10px;
    margin: 0 auto;
    overflow: hidden;
    color: #727272;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -1px;
        border-right: 3px solid #dfdfe0;
        height: 100%;
        display: block;
    }

    .timeline-row {
        padding-left: 50%;
        position: relative;
        margin-bottom: 30px;

        h2 {
            position: absolute;
            right: 50%;
            top: 31px;
            text-align: right;
            margin-right: 20px;
            font-size: 14pt;
            text-transform: uppercase;
            color: #454545;
        }

        .timeline-content {
            position: relative;
            padding: 15px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;

            &:after {
                content: "";
                position: absolute;
                top: 20px;
                height: 3px;
                width: 40px;
            }

            &:before {
                content: "";
                position: absolute;
                top: 20px;
                right: -50px;
                width: 20px;
                height: 20px;
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                border-radius: 100px;
                z-index: 100;
                background: #ea4b2c;
                border: 3px solid #23639d;
            }

            .d-flex {
                border-bottom: solid 1px #dfdfe0;
                margin-bottom: 15px;
                padding-bottom: 10px;

                h3 {
                    text-overflow: ellipsis;
                    line-height: 120%;
                    font-size: 14pt;
                    text-transform: uppercase;
                    color: #454545;
                    align-self: flex-end;
                    font-weight: 700;
                    order: 1;
                }

                span {
                    font-size: 14pt;
                    padding: 4px 12px;
                    background: #23639d;
                    border-radius: 0.35rem;
                    min-width: 90px;
                    max-width: max-content;
                    height: min-content;
                    margin-right: 5px;
                    flex-grow: 1;
                    color: #fff;
                    text-align: center;
                    order: 2;
                }
            }

            .into-tour {
                span {
                    font-size: 10pt;
                    padding: 2px 8px;
                    background: #23639d;
                    border-radius: 0.35rem;
                    color: white;
                }
            }
        }

        &:nth-child(odd) {
            h2 {
                top: 20px;
            }

            .timeline-content {
                background-color: #eeeeee;
                margin-left: 40px;
                text-align: left;
                border: solid 1px #dfdfe0;

                &:after {
                    left: -40px;
                    border-right: 18px solid #dfdfe0;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                }

                &:before {
                    left: -50px;
                    right: initial;
                }
            }
        }

        &:nth-child(even) {
            padding-left: 0;
            padding-right: 50%;

            h2 {
                right: auto;
                left: 50%;
                text-align: left;
                margin-right: 0;
                margin-left: 30px;
                top: 20px;
            }

            .timeline-content {
                background-color: #eeeeee;
                margin-right: 40px;
                margin-left: 0;
                text-align: left;
                border: solid 1px #dfdfe0;

                &:after {
                    right: -40px;
                    border-left: 18px solid #dfdfe0;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .timeline-itinerary {
        padding: 15px 10px;
    }

    .timeline-itinerary:after {
        left: 28px;
    }

    .timeline-itinerary .timeline-row {
        padding-left: 0;
        margin-bottom: 16px;
    }

    .timeline-itinerary .timeline-row h2 {
        position: relative !important;
        right: auto !important;
        left: 0 !important;
        top: 0 !important;
        text-align: left !important;
        margin: 0 0 6px 56px !important;
    }

    .timeline-itinerary .timeline-row .timeline-content {
        padding: 15px;
        margin-left: 56px;
        position: relative;
    }

    .timeline-itinerary .timeline-row .timeline-content:after {
        right: auto;
        left: -39px;
        top: 32px;
    }

    .timeline-itinerary .timeline-row:nth-child(even) {
        padding-right: 0;
    }

    .timeline-itinerary .timeline-row:nth-child(even) .timeline-content:before,
    .timeline-itinerary .timeline-row:nth-child(odd) .timeline-content:before {
        top: 32px;
        left: -47px;
    }

    .timeline-itinerary .timeline-row:nth-child(even) .timeline-content,
    .timeline-itinerary .timeline-row:nth-child(odd) .timeline-content {
        margin-right: 0;
        margin-left: 55px;
    }

    .timeline-itinerary .timeline-row:nth-child(even) .timeline-content:after {
        right: auto;
        left: -39px;
        top: 32px;
        border-right: 18px solid #eeeeee;
        border-left: inherit;
    }
}

@media (max-width: 500px){
    .timeline-itinerary .timeline-row .timeline-content{
        .d-flex{
            display: block !important;

            h3{
                margin-top: 5px;
            }
        }
    }
}