.filters-left-sidebar {
    border-radius: 0;
    border-color: #d1d5d7;

    @media (max-width: 991.98px) {
        margin-bottom: 1.5rem;
    }

    @media (min-width: 992px) {
        .media-large-none {
            display: none;
        }

        .collapse {
            display: block;
        }
    }

    .navbar-light {
        button {
            background: transparent;
        }
    }

    .list-group-item-action {
        background: #f5f5f5;
        padding: 15px;

        .list-group-flush {
            span {
                color: #454545;
                font-size: 10pt;
                font-weight: 400;
                background: #e5e5e5;
                border: solid 1px #b5b4b4;
                border-radius: 4px;
                width: 38px;
                height: 30px;
                line-height: 22px;
            }

            .custom-checkbox {
                label {
                    font-size: 13pt;
                    line-height: 17pt;
                    color: #444343;
                }
            }

            .vote {
                display: inline-block;
                color: #9f9f9f;
                margin-bottom: 0;
                font-size: 11pt !important;

                i.active {
                    color: #f8d25d;
                }
            }
        }

        label {
            color: #171818;
            font-size: 11pt;
        }

        h2 {
            font-size: 13pt;
            color: #454545;
            text-transform: uppercase;
        }

        small {
            font-size: 11pt;
            color: #454545;
        }

        .list-group-item {
            background: none;
            border: none;
            padding: 12px 0 0;
        }

        .filter-search{
            color: #fff;
            background-color: #23639d;
            padding: 10px;
            font-size: 13pt;
            border-radius: .3rem;
        }
    }
}

.filters-aside {
    label {
        font-size: 13pt;
        color: #454545;
        text-transform: uppercase;
    }
}